import { connect, useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import styles from "./CourseSidebar.scss";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { TABS, SIDEBARS, CONFIGS } from "../../constants/constants";
import home_sidebar_active from "../../images/home-sidebar-ac.svg";
import home_sidebar from "../../images/home-sidebar.svg";
import create_grp_sidebar from "../../images/create-grp-sidebar.svg";
import create_grp_sidebar_ac from "../../images/create-grp-sidebar-ac.svg";
import join_grp_sidebar from "../../images/join-grp-sidebar.svg";
import join_grp_sidebar_active from "../../images/join-grp-sidebar-ac.svg";
import explore_sidebar from "../../images/explore-sidebar.svg";
import explore_sidebar_active from "../../images/explore-sidebar-ac.svg";
import group_sidebar from "../../images/group-sidebar.svg";
import course_sidebar from "../../images/course-sidebar.svg";
import course_sidebar_active from "../../images/course-sidebar-ac.svg";
import group_sidebar_active from "../../images/group-sidebar-ac.svg";
import { showSidebarMB } from "../../actions/utilAction";
import white_cross from "../../images/white-cross.svg";
import logo_sidebar from "../../images/eckovation-sidebar.png";
import { withRouter } from "react-router";
import eck_logo from "../../images/imarticusLogo.svg";
import eck_logo_wt from "../../images/Imarticus-logo-white.png";
import { resetGDBack } from "../../actions/groupAction";
import { showFreshDesk } from "../../actions/showFreshDesk";
import {
  setCurrentTab,
  setSidebar,
  resetLoadingData,
} from "../../actions/utilAction";
import SidebarSS from "../skeletonscreens/SidebarSS";
import { getPrimaryEmailsWithoutModal } from "../../actions/primaryEmailActions";
import { setLoadingData } from "../../actions/utilAction";
import cookie from "react-cookies";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Icon, SvgIcon } from "@material-ui/core";
import { getParam } from "../../actions/courseAction";
import { impartusUserLogin } from "../../actions/batchAction";

const cookies = new Cookies();

class CourseSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_crs: false,
      is_grp: false,
      loading: true,
    };
  }

  closeSidebar = () => {
    this.props.showSidebarMB(false);
  };
  componentDidMount() {
    console.log("pathname", this.props.location.pathname);
    this.props.setSidebar(SIDEBARS.HOME_SIDEBAR);
    if (this.props.groups) {
      this.checkGroupsAndCourses();
    }
    if (this.props.courses) {
      this.checkGroupsAndCourses();
    }
    if (this.props.gd_is_back) {
      this.props.resetGDBack();
    }
    // setTimeout(
    // 	function() {
    // 		this.props.resetLoadingData();
    // 	}
    // 	.bind(this),
    // 	1000
    // );
    // this.showFreshDesk=this.showFreshDesk.bind(this);
  }
  componentDidUpdate(prev_props) {
    if (this.props.groups != prev_props.groups) {
      this.checkGroupsAndCourses();
    }
    if (this.props.courses != prev_props.courses) {
      this.checkGroupsAndCourses();
    }
    if (
      this.props.is_data_loading != prev_props.is_data_loading &&
      this.props.is_data_loading
    ) {
      // setTimeout(
      // 	function() {
      // 		this.props.resetLoadingData();
      // 	}
      // 	.bind(this),
      // 	1000
      // );
    }
  }
  goBackInHistory = () => {
    this.props.history.goBack();
  };
  checkGroupsAndCourses = () => {
    if (this.props.groups && this.props.groups.length > 0) {
      this.setState({
        is_grp: true,
      });
    } else {
      this.setState({
        is_grp: false,
      });
    }
    if (this.props.courses && this.props.courses.length > 0) {
      this.setState({
        is_crs: true,
      });
    } else {
      this.setState({
        is_crs: false,
      });
      var pathname = this.props.location.pathname;
    }
    if (pathname == "/dashboard" || pathname == "/dashboard/") {
      // alert("yes");
      if (
        this.props.courses &&
        this.props.courses.length > 0 &&
        this.props.groups &&
        this.props.groups.length > 0
      ) {
        // alert("1");
        this.props.setCurrentTab(TABS.MY_COURSES);
      } else if (
        (!this.props.courses || this.props.courses.length == 0) &&
        this.props.groups &&
        this.props.groups.length > 0
      ) {
        // alert("2");
        this.props.setCurrentTab(TABS.MY_GROUPS);
      } else {
        // alert("3");
        this.props.setCurrentTab(TABS.HOME);
      }
    }
  };
  goToDashboard = () => {
    if (this.props.location.pathname.includes("/gradebook/")) {
      this.props.setLoadingData();
    }
    this.props.history.push({
      pathname: "/dashboard/",
    });
  };
  goToGradebook = () => {
    // /gradebook/:pid/:gid/:cid'
    let pid = cookies.get("pid");
    let gid = this.props.selected_course.gids
      ? this.props.selected_course.gids[0]
      : null;
    let cid = this.props.selected_course
      ? this.props.selected_course._id
      : null;
    if (pid && gid && cid) {
      let url =
        CONFIGS.WEB_DASHBOARD_BASE_URL +
        "gradebook" +
        "/" +
        pid +
        "/" +
        gid +
        "/" +
        cid;
      console.log({ url });
      window.location.replace(url);
    }
  };
  goToCareerServices = () => {
    // /gradebook/:pid/:gid/:cid'
    console.log({ sc: this.props.selected_course });
    let pid = cookies.get("pid");
    let gid = this.props.selected_course.gids
      ? this.props.selected_course.gids[0]
      : null;
    let cid = this.props.selected_course
      ? this.props.selected_course._id
      : null;
    if (pid && gid && cid) {
      let url =
        CONFIGS.WEB_DASHBOARD_BASE_URL +
        "placement-portal" +
        "/" +
        pid +
        "/" +
        gid +
        "/" +
        cid;
      console.log({ url });
      window.location.replace(url);
    }
  };
  goToAttendance = () => {
    // /gradebook/:pid/:gid/:cid'
    console.log({ sc: this.props.selected_course });
    let pid = cookies.get("pid");
    let gid = this.props.selected_course.gids
      ? this.props.selected_course.gids[0]
      : null;
    let cid = this.props.selected_course
      ? this.props.selected_course._id
      : null;
    if (pid && gid && cid) {
      let url =
        CONFIGS.WEB_DASHBOARD_BASE_URL +
        "attendance" +
        "/" +
        pid +
        "/" +
        gid +
        "/" +
        cid;
      console.log({ url });
      window.location.replace(url);
    }
  };
  goToCalendar = () => {
    // /gradebook/:pid/:gid/:cid'
    console.log({ sc: this.props.selected_course });
    let pid = cookies.get("pid");
    let gid = this.props.selected_course.gids
      ? this.props.selected_course.gids[0]
      : null;
    let cid = this.props.selected_course
      ? this.props.selected_course._id
      : null;
    if (pid && gid && cid) {
      let url =
        CONFIGS.WEB_DASHBOARD_BASE_URL +
        "calendar" +
        "/user/" +
        pid +
        "/" +
        gid +
        "/" +
        cid;
      console.log({ url });
      window.location.replace(url);
    }
  };
  goToDiscussion = () => {
    // /gradebook/:pid/:gid/:cid'
    let pid = cookies.get("pid");
    let gid = this.props.selected_course.gids
      ? this.props.selected_course.gids[0]
      : null;
    let cid = this.props.selected_course
      ? this.props.selected_course._id
      : getParam("cid");
    if (pid && gid) {
      let url = CONFIGS.MY_COURSE_DISCUSSIONURL + gid + "?pid=" + pid;
      console.log({ url });
      window.location.replace(url);
    }
  };
  redirectToThisCourse = () => {
    let cid = this.props.selected_course
      ? this.props.selected_course._id
      : getParam("cid");
    if (this.props.selected_course?.level_labels?.length == 1) {
      var url = `https://pegasust.imarticus.org/batch/undefined/653b537a74f4701658d9c0ac/653b533774f4701658d9b3d5/undefined`;
    } else {
      url = CONFIGS.WEB_DASHBOARD_BASE_URL + "view?cid=" + cid;
    }
    console.log({ url });
    window.location.replace(url);
  };
  redirectToCourse = () => {
    console.log("\n\n\n--- In redirectToCourse --- \n\n\n");
    console.log("course", this.props.selected_course);
    console.log("cid::", this.props.selected_course._id);
    var expiredate = new Date();
    expiredate.setDate(expiredate.getDate() + 180);
    cookies.set("cid", this.props.selected_course._id, {
      expires: expiredate,
      domain: CONFIGS.DOMAIN,
      path: "/",
    });
    // window.history.pushState([], "Dashboard", window.location.href);
    // let courseSelected = props.courses.find((el) => el._id == course._id);
    if (
      "level_labels" in this.props.selected_course &&
      this.props.selected_course.level_labels.length == 1
    ) {
      console.log("direct::::::::");
      let pid = cookies.get("pid");
      let gid = this.props.selected_course.gids
        ? this.props.selected_course.gids[0]
        : null;
      let cid = this.props.selected_course
        ? this.props.selected_course._id
        : null;
      console.log({ pid, gid, cid });
      if (gid && cid) {
        console.log(
          "checking for user version",
          this.props.profiles?.[0].user_version
        );
        var url =
          CONFIGS.WEB_DASHBOARD_BASE_URL +
          "batch" +
          "/" +
          pid +
          "/" +
          gid +
          "/" +
          cid +
          "/undefined";
        console.log({ url });
        // return;
      }
    } else {
      let cid = this.props.selected_course
        ? this.props.selected_course._id
        : getParam("cid");
      url = CONFIGS.WEB_DASHBOARD_BASE_URL + "view?cid=" + cid;
    }
    window.location.href = url; //userversion key
    // props.setSelectedCourse(this.props.selected_course);
    // setCourseOpen(true);
    // window.location.href = "/course?cid=" + course._id;
  };

  impartusLogin = async () => {
    impartusUserLogin(this.props.selected_course._id)
      .then(function (response) {
        const token = response.data.data.token;
        var newWin = window.open(
          "https://a.impartus.com/gateway/index.html?token=" + token,
          "_blank"
        );
        if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
          alert("Please allow browser popups to view className recordings");
        }
      })
      .catch(function (err) {
        alert(err.data.message);
        console.log(err);
      });
  };

  render() {
    console.log(
      "\n Props ::: ",
      this.props,
      this.state.loading,
      this.props.is_data_loading,
      "\n"
    );
    if (this.props.is_data_loading) {
      return <SidebarSS />;
    } else {
      return (
        <Fragment>
          {/* <div className="home-left-sidebar courseSidebar">
            <div className="menu-list left-sidebar-menu">
              <Link to="/dashboard">
                <div className="logo-container center-all"
                style={{
                  backgroundColor: `${
                    props.brand && props.brand.whiteBackground ? "white" : ""
                  }`,
                }}>
                  <img src={eck_logo_wt} height="60px" width="60px" />
                </div>
              </Link>
              <ul className="sidebar-list">
                <a href='/dashboard'>
                  <li className="sidebar-list-item" >
                    <Icon  className="fa fa-chevron-left" style={{ fontSize: '16px', fontWeight: '600'}}></Icon>Back
                  </li>
                </a>
                <a href='/'>
                  <li className={"sidebar-list-item " + (this.props.location.pathname == '/course' ? "active" : "")}>Course</li>
                </a>
                {
                  Object.keys(this.props.selected_course).length>5?(
                    <Fragment>
                      <a onClick={this.goToDiscussion}>
                        <li className="sidebar-list-item">Discussion</li>
                      </a>
                      <a onClick={this.goToGradebook}>
                        <li className="sidebar-list-item">Gradebook</li>
                      </a>
                    </Fragment>
                  ):null
                }
              </ul>
              <div className="side-bar-freshdesk-container">
                <p className="side-bar-freshdesk-heading">Facing problems?</p>
                <button
                  style={{ margin: "0 auto" }}
                  className="side-bar-freshdesk-button"
                  onClick={() => {
                    this.showFreshDesk();
                  }}
                >
                  Get help
                </button>
              </div>
            </div>
          </div> */}

          <div className="home-left-sidebar">
            <div className="menu-list left-sidebar-menu">
              <Link to="/dashboard">
                <div
                  onClick={() => {
                    if (this.props.location.pathname.includes("/gradebook/")) {
                      this.props.setLoadingData();
                    }
                  }}
                  className="logo-container center-all"
                  style={{
                    backgroundColor: `${
                      this.props.brand && this.props.brand.whiteBackground
                        ? "white"
                        : ""
                    }`,
                  }}
                >
                  {!this.props.is_data_loading && (
                    <img
                      src={
                        this.props.brand
                          ? this.props.brand.pic
                          : "https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg"
                      }
                    />
                  )}
                </div>
              </Link>
              <ul className="sidebar-list">
                {/* <a href="/dashboard">
                  <li className="sidebar-list-item">
                    <Icon
                      className="fa fa-chevron-left"
                      style={{ fontSize: "16px", fontWeight: "600" }}
                    ></Icon>
                    Back
                  </li>
                </a> */}
                {this.props.location.pathname.includes("/gradebook/") && (
                  <a onClick={this.goBackInHistory}>
                    <li
                      className={"sidebar-list-item "}
                      style={{ fontSize: "20px" }}
                    >
                      <span>
                        <i
                          className="fa fa-chevron-left"
                          style={{ paddingRight: "4px" }}
                        ></i>{" "}
                        Back
                      </span>
                    </li>
                  </a>
                )}
                <a onClick={this.redirectToCourse}>
                  <li
                    className={
                      "sidebar-list-item " +
                      (["/view/", "/view"].indexOf(
                        this.props.location.pathname
                      ) != -1
                        ? "active"
                        : "")
                    }
                  >
                    Course
                  </li>
                </a>
                {Object.keys(this.props.selected_course).length > 5 ? (
                  <Fragment>
                    <a onClick={this.goToDiscussion}>
                      <li className="sidebar-list-item">Discussion</li>
                    </a>
                    {!this.props.selected_course.hide_gradebook && (
                      <a
                        onClick={this.goToGradebook}
                        style={
                          this.props.location.pathname.includes("/gradebook/")
                            ? { pointerEvents: "none", cursor: "default" }
                            : {}
                        }
                      >
                        <li
                          className={
                            "sidebar-list-item " +
                            (this.props.location.pathname.includes(
                              "/gradebook/"
                            )
                              ? "active"
                              : "")
                          }
                        >
                          Gradebook
                        </li>
                      </a>
                    )}
                    {this.props.selected_course.isPlacementEnabled &&
                      this.props.selected_course.learnerAccess && (
                        <a
                          onClick={this.goToCareerServices}
                          style={
                            this.props.location.pathname.includes(
                              "/placement-portal/"
                            )
                              ? { pointerEvents: "none", cursor: "default" }
                              : {}
                          }
                        >
                          <li
                            className={
                              "sidebar-list-item " +
                              (this.props.location.pathname.includes(
                                "/placement-portal/"
                              )
                                ? "active"
                                : "")
                            }
                          >
                            Career Services
                          </li>
                        </a>
                      )}
                    {this.props.selected_course.impartus_enabled && (
                      <button
                        className="impartus-button"
                        style={{ marginTop: "16px" }}
                        onClick={this.impartusLogin}
                      >
                        <img
                          className="recording-icon-image"
                          src="http://cdn.pegasus.imarticus.org/index/recording_neutral.svg"
                        />
                        Class Recordings
                      </button>
                    )}
                    {console.log(
                      "showAttendance :: ",
                      this.props.showAttendance
                    )}
                    {this.props.showAttendance && (
                      <a
                        onClick={this.goToAttendance}
                        style={
                          this.props.location.pathname.includes("/attendance/")
                            ? { pointerEvents: "none", cursor: "default" }
                            : {}
                        }
                      >
                        <li
                          className={
                            "sidebar-list-item " +
                            (this.props.location.pathname.includes(
                              "/attendance/"
                            )
                              ? "active"
                              : "")
                          }
                        >
                          Attendance
                        </li>
                      </a>
                    )}
                    {this.props.showAttendance && (
                      <a
                        onClick={this.goToCalendar}
                        style={
                          this.props.location.pathname.includes("/calendar/")
                            ? { pointerEvents: "none", cursor: "default" }
                            : {}
                        }
                      >
                        <li
                          className={
                            "sidebar-list-item " +
                            (this.props.location.pathname.includes("/calendar/")
                              ? "active"
                              : "")
                          }
                        >
                          Calendar
                        </li>
                      </a>
                    )}
                  </Fragment>
                ) : null}
              </ul>
              {!this.props.is_data_loading &&
                (this.props.brand == undefined ||
                  (this.props.brand &&
                    Object.keys(this.props.brand).length == 0) ||
                  this.props.brand.getHelpEnabled == true) && (
                  <div className="side-bar-freshdesk-container">
                    <p className="side-bar-freshdesk-heading">
                      Facing problems?
                    </p>
                    <button
                      style={{ margin: "0 auto" }}
                      className="side-bar-freshdesk-button"
                      onClick={() => {
                        showFreshDesk(
                          [this.props.selected_course],
                          this.props.contact,
                          this.props.profileName,
                          this.props.profile.email
                        );
                      }}
                    >
                      Get help
                    </button>
                  </div>
                )}
            </div>
          </div>
          <div
            onClick={this.closeSidebar}
            className={this.props.show_sidebar_mb ? "demo" : "hide"}
          ></div>
          <div className={"home-left-sidebar-mb "}>
            <div
              className={
                "left-sidebar-menu-mb " +
                (this.props.show_sidebar_mb ? "out" : "")
              }
            >
              <div className="logo-container">
                <button
                  className={this.props.show_sidebar_mb ? "close-btn" : "hide"}
                  onClick={this.closeSidebar}
                >
                  <img src={white_cross}></img>
                </button>
                {!this.props.is_data_loading && (
                  <img
                    className="logo-sidebar"
                    onClick={this.goToDashboard}
                    src={
                      this.props.brand
                        ? this.props.brand.pic
                        : "https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg"
                    }
                  ></img>
                )}
              </div>
              <ul className="sidebar-list">
                {/* <a href="/dashboard">
                  <li className="sidebar-list-item">
                    <Icon
                      className="fa fa-chevron-left"
                      style={{ fontSize: "16px", fontWeight: "600" }}
                    ></Icon>
                    Back
                  </li>
                </a> */}
                <a onClick={this.redirectToCourse}>
                  <li
                    className={
                      "sidebar-list-item " +
                      (["/view/", "/view"].indexOf(
                        this.props.location.pathname
                      ) != -1
                        ? "active"
                        : "")
                    }
                  >
                    Course
                  </li>
                </a>

                {Object.keys(this.props.selected_course).length > 5 ? (
                  <Fragment>
                    <a onClick={this.goToDiscussion}>
                      <li className="sidebar-list-item">Discussion</li>
                    </a>
                    {!this.props.selected_course.hide_gradebook && (
                      <a onClick={this.goToGradebook}>
                        <li
                          className={
                            "sidebar-list-item " +
                            "sidebar-list-item " +
                            (this.props.location.pathname.includes("/gradebook")
                              ? "active"
                              : "")
                          }
                        >
                          Gradebook
                        </li>
                      </a>
                    )}
                    {this.props.selected_course.impartus_enabled && (
                      <button
                        className="impartus-button"
                        style={{ marginTop: "16px" }}
                        onClick={this.impartusLogin}
                      >
                        <img
                          className="recording-icon-image"
                          src="http://cdn.pegasus.imarticus.org/index/recording_neutral.svg"
                        />
                        Class Recordings
                      </button>
                    )}
                  </Fragment>
                ) : null}
              </ul>
              {!this.props.is_data_loading &&
                (this.props.brand == undefined ||
                  (this.props.brand &&
                    Object.keys(this.props.brand).length == 0) ||
                  this.props.brand.getHelpEnabled == true) && (
                  <div className="side-bar-freshdesk-container">
                    <p className="side-bar-freshdesk-heading">
                      Facing problems?
                    </p>
                    <button
                      style={{ margin: "0 auto" }}
                      className="side-bar-freshdesk-button"
                      onClick={() => {
                        showFreshDesk(
                          this.props.selected_course,
                          this.props.contact,
                          this.props.profileName,
                          this.props.profile.email
                        );
                      }}
                    >
                      Get help
                    </button>
                  </div>
                )}
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  show_sidebar_mb: state.utils.show_sidebar_mb,
  current_tab: state.utils.current_tab,
  show_my_crs: state.utils.show_my_crs,
  show_my_grp: state.utils.show_my_grp,
  show_home: state.utils.show_home,
  groups: state.j_grp.groups,
  courses: state.course.courses,
  is_data_loading: state.utils.is_data_loading,
  gd_is_back: state.j_grp.gd_is_back,
  profileName: state.profile.p_name,
  email: state.primaryEmail.secondary_emails[0],
  selected_course: state.course.selected_course,
  brand: state.course.brand || state.course.brand,
  individualBrand: state.course.individualBrand,
  contact: state.profile.contact,
  profile: state.profile,
  courses: state.course.courses,
  showAttendance: state.course.showAttendance,
});

const mapDispatchToProps = {
  showSidebarMB,
  setCurrentTab,
  setSidebar,
  resetLoadingData,
  resetGDBack,
  getPrimaryEmailsWithoutModal,
  getParam,
  setLoadingData,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CourseSidebar)
);
