import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CONFIGS, SIDEBARS } from "../../../constants/constants";
import pencil from "../../../images/pencil.svg";
import ProfileDetailCard from "../../utils/ProfileDetailCard";
import { utils } from "../../../utility/utils";
import { withRouter, useHistory } from "react-router";
import Loader from "../../utils/Loader.js";
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  Input,
  ModalBody,
  Label,
  FormGroup,
  Row,
  Col,
} from "reactstrap";
import { ROUTES } from "../../../constants/routes.js";
import { setSidebar } from "../../../actions/utilAction.js";

const EditWindow = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector((state) => state.profile);
  const [togglePDFDoc, setTogglePDFDoc] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const { primary_email } = useSelector((state) => state.primaryEmail);
  console.log({ profile });
  useEffect(() => {
    setIsLoading(true);
    var courseList = [];
    let data = {
      headers: {
        "Content-Type": "application/json",
      },
      tokn: utils.getAt(),
      d_id: utils.getDid(),
      cl: "W",
    };
    Axios.post(ROUTES.MY_CRS, data).then(async (my_courses) => {
      var res = my_courses.data.data;
      courseList = res.courses;
      if (Array.isArray(courseList)) {
        const enabledCourses = [];
        const seenIds = new Set();
        courseList.forEach((course) => {
          if (course.isPlacementEnabled && !seenIds.has(course.crs_pg_id)) {
            enabledCourses.push(course);
            seenIds.add(course.crs_pg_id);
          }
        });
        console.log(
          "hereeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
        );
        console.log({ enabledCourses });
        if (courseList.length > 0) {
          if (enabledCourses.length === 1) {
            const crs_pg_id = enabledCourses[0].crs_pg_id;
            history.push(
              `/placement-module/profile_preview/?crs_pg_id=${crs_pg_id}`
            );
          } else if (enabledCourses.length > 1) {
            const courseObjects = enabledCourses.map((course) => ({
              crs_pg_id: course.crs_pg_id,
              name: course.name,
            }));
            setCourses(courseObjects);
            setIsLoading(true);
            setTogglePDFDoc(true);
          } else {
            // setIsLoading(false);
            console.log("in else condition but why ");
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
          }
        } else {
          setIsLoading(false);
        }
      }
    });
  }, [history]);

  let creationDate = new Date(profile.profile_map.cat).toString();

  const previewResumeClick = () => {
    setTogglePDFDoc(true);
  };

  const handleCourseClick = (crs_pg_id) => {
    history.push(`/placement-module/profile_preview/?crs_pg_id=${crs_pg_id}`);
  };

  const changeEmailSubscription = () => {
    console.log(" --- Changed the subscription --- ");
    Axios.post(`${CONFIGS.API_API_ENDPOINT}notifications/sbscrb_email`, {
      aid: utils.getAid(),
      tokn: utils.getAt(),
      ifSubscribed: !profile.ifSubscribed,
    })
      .then((res) => {
        console.log("Response successful!");
        dispatch({
          type: "CHANGE_SUBSCRIPTION",
          ifSubscribed: !profile.ifSubscribed,
        });
        return;
      })
      .catch((err) => {
        console.log("Error: ", err);
        return;
      });
  };

  if (isLoading) {
    console.log("yesssssssssssssssssssssssssssssssssssssssssssssssssss");
    return (
      <>
        <Modal
          isOpen={togglePDFDoc}
          size={"md"}
          style={{ minwidth: "400px" }}
          centered={true}
        >
          <ModalHeader
            className="resume-prev"
            style={{ paddingRight: "7px", width: "100%" }}
          >
            <div className="row" style={{ justifyContent: "space-between" }}>
              <div>Choose Course Profile to Preview </div>
              <div>
                {/* <img
                  src="https://webcdn.imarticus.org/Placement/Group926.svg"
                  style={{ paddingRight: "7px" }}
                  onClick={() => setTogglePDFDoc(false)}
                /> */}
              </div>
            </div>
          </ModalHeader>
          <ModalBody
            style={{
              maxHeight: "500px",
              overflowY: "scroll",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            {courses.map((course) => (
              <button
                key={course.crs_pg_id}
                onClick={() => handleCourseClick(course.crs_pg_id)}
                style={{
                  margin: "10px",
                  padding: "10px 20px",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                {course.name}
              </button>
            ))}
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center" }}></ModalFooter>
        </Modal>
        <Loader />
      </>
    );
  }
  if (!isLoading) {
    return (
      <>
        <div className="group-db-content-wrapper">
          <div className="container-fluid dashboard-content">
            <div className=" profile-setting-container-row">
              <div className="profile-setting-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="row justify-content-center">
                  <div className="ps-main-content col-xl-8 col-lg-8 col-md-6 col-sm-6 ">
                    <p className="edit-profile-heading">Profile Details</p>
                    <div
                      className="img-container"
                      style={{ marginBottom: "16px" }}
                    >
                      <img className="profile-img" src={profile.ppic}></img>
                      {/* <div className="edit-img-container">
                        <div className="edit-img">
                          <img src={pencil} />
                        </div>
                      </div> */}
                    </div>
                    <div className="edit-window-profile-details">
                      <ProfileDetailCard
                        detailName={"Name"}
                        detailValue={profile.p_name}
                        url={"/dashboard/edit/profile"}
                      />
                      <ProfileDetailCard
                        detailName={"Primary Email"}
                        detailValue={primary_email}
                        url={"/dashboard/edit/email"}
                        ifVerified={profile.ep_vrfy}
                      />

                      <ProfileDetailCard
                        detailName={"Registered Mobile Number"}
                        detailValue={profile.contact}
                        url={"/dashboard/edit/mobile"}
                        ifVerified={profile.contact != undefined}
                      />

                      <ProfileDetailCard
                        detailName={"Preferred Timezone"}
                        detailValue={profile.tz}
                        url={"/dashboard/edit/timezone"}
                      />
                      <br />
                      {/* <p style={{ fontSize: "18px" }}>
                        <b>Subscribe to email notifications by Imarticus? </b>
                        {console.log("\n\n\n ifSubscribed :::: ", {
                          pifSub: profile.ifSubscribed,
                        })}
                        <input
                          checked={profile.ifSubscribed}
                          onChange={changeEmailSubscription}
                          className="form-check-input"
                          style={{ float: "right", position: "inherit" }}
                          type="checkbox"
                          name="subscribeToEmail"
                        />
                        (Currently {profile.ifSubscribed ? "On" : "Off"})
                      </p> */}
                      <br />
                      <div
                        style={{
                          color: "rgba(60, 72, 82, 0.75)",
                          textAlign: "center",
                        }}
                      >
                        <b>Account registered at </b>
                        {creationDate}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};
export default EditWindow;
